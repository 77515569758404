.state {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

.state__animations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.state__animation-child {
    position: absolute;
    pointer-events: auto;
    transform-origin: 0 0;
}

.state__animation-child-inner {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
}

@supports (overflow: clip) {
    .state__animation-child-inner {
        overflow: clip;
    }
}
