.text {
    display: flex;
    /* Already hidden in outer */
    /*overflow: hidden;*/
}

/*@supports (overflow: clip) {
    .text {
        overflow: clip;
    }
}*/

.text_halign_left {
    justify-content: flex-start;
    text-align: left;
}

.text_halign_center {
    justify-content: center;
    text-align: center;
}

.text_halign_right {
    justify-content: flex-end;
    text-align: right;
}

.text_valign_top {
    align-items: flex-start;
}

.text_valign_center {
    align-items: center;
}

.text_valign_bottom {
    align-items: flex-end;
}

.text_width_content {
    min-width: max-content;
}

.text_height_content {
    min-height: max-content;
}

.text__inner {
    overflow: hidden;
}

.text_singleline .text__inner {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text_truncate_none .text_singleline .text__inner {
    text-overflow: clip;
}

.text_multiline .text__inner {
    /* For -webkit-line-clamp */
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text__inner_gradient {
    color: transparent;
    background-clip: text;
}

.text__image {
    display: inline-block;
    vertical-align: middle;
}

.text__image_hidden {
    display: none;
}
