.pager {}

.pager__items {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;

    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    scroll-snap-type: mandatory;
    scroll-snap-type: both mandatory;
}

.pager__items::-webkit-scrollbar {
    display: none;
}

.pager_orientation_horizontal .pager__items {
    justify-content: start;
    grid-auto-flow: column;
    grid-template-rows: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.pager_orientation_vertical .pager__items {
    align-content: start;
    grid-auto-flow: row;
    grid-template-columns: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
