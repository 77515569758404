.input {
    display: flex;
    min-width: 0;
    min-height: 0;
}

.input__wrapper {
    position: relative;
    display: block;
    flex: 1 1 auto;
    max-width: 100%;
    max-height: 100%;
}

.input__holder {
    display: block;
    max-height: 100%;
    visibility: hidden;
    overflow: hidden;
    white-space: nowrap;
}

.input_multiline .input__holder {
    white-space: pre-wrap;
    overflow-y: auto;
}

.input__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font: inherit;
    line-height: inherit;
    appearance: none;
    /* to hide arrows in firefox */
    -moz-appearance: textfield;
    background: none;
    border: none;
    border-radius: 0;
    /*outline: none;*/
    white-space: pre-wrap;
    resize: none;
    overflow: hidden;
}

.input__input::-webkit-inner-spin-button,
.input__input::-webkit-outer-spin-button {
    display: none;
}

.input__input::placeholder {
    color: var(--divkit-input-hint-color, rgba(0, 0, 0, .45));
}

.input_highlight-color .input__input::selection {
    background: var(--divkit-input-highlight-color);
}

.input_vertical-overflow textarea.input__input {
    overflow-y: scroll;
}
